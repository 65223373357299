import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { InitialService } from '@services/initial.service';
import { getLogoImage } from '@helpers/images';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  mainLoader: Observable<boolean>;
  error: Observable<boolean>;
  logo = getLogoImage();

  constructor(
    private initialService: InitialService
  ) {
    this.mainLoader = this.initialService.isLoaded;
    this.error = this.initialService.isError;
    this.initialService.getInitialData();
  }

}
