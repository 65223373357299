import { Injectable } from '@angular/core';
import { getCurrency, setCurrency } from '@helpers/currency';
import { CurrencyEnum, CurrencyRate, IUserResponse } from '@types';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  private _currentCurrency = new BehaviorSubject<CurrencyEnum>(getCurrency());
  private _currentCurrencyAsObservable = this._currentCurrency.asObservable();

  get currency() {
    return this._currentCurrencyAsObservable;
  }

  private _currenciesRate: CurrencyRate[] = [];

  get currenciesRate() {
    return this._currenciesRate;
  }

  get currencyList() {
    return [
      CurrencyEnum.EUR,
      CurrencyEnum.PLN,
      CurrencyEnum.RUB,
      CurrencyEnum.USD
    ]
  }

  constructor(private UserService: UserService) { }

  setCurrenciesRateList(items: CurrencyRate[]) {
    this._currenciesRate = items;
  }

  changeCurrency(currency: CurrencyEnum) {
    this.setCurrency(currency);
    this.UserService.setCurrency(currency)
      .pipe(take(1))
      .subscribe((data: IUserResponse) => {
        this.UserService.setUserData(data.user);
      });
  }

  setCurrency(currency: CurrencyEnum) {
    this._currentCurrency.next(currency);
    setCurrency(currency);
  }

  recalculate(value: number) {
    const currency = this.currenciesRate.find(item => item.currency === this._currentCurrency.getValue());

    if (currency) {
      return currency.rate * value;
    }

    return value;
  }
}
