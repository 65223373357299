import { Component, OnInit } from '@angular/core';
import { PopupService } from '@services/popup.service';

@Component({
  selector: 'shared-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  constructor(private PopupService: PopupService) { }

  ngOnInit(): void {
  }

  closePopup() {
    this.PopupService.hideLatest();
  }
}
