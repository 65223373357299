import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

export function getDiff(currentDateVal: string, dateVal: string) {
  const date = prepareDate(dateVal);
  const currentDate = prepareDate(currentDateVal);

  const days = date.diff(currentDate, 'days');
  const hours = date.diff(currentDate, 'hour') % 24;
  const minutes = date.diff(currentDate, 'minute') % 60;
  const seconds = date.diff(currentDate, 'second') % 60;

  return {
    raw: {
      days,
      hours,
      minutes,
      seconds
    },
    formatted: {
      days: formatTimeNumber(days),
      hours: formatTimeNumber(hours),
      minutes: formatTimeNumber(minutes),
      seconds: formatTimeNumber(seconds),
    }
  }
};

export function prepareDate(date: string): Moment {
  return moment.utc(date).local();
}

export function toW3CString(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const offset = -date.getTimezoneOffset();
  const offsetHours = Math.abs(Math.floor(offset / 60));
  const offsetMinutes = Math.abs(offset) - offsetHours * 60;
  let offsetSign = '+';
  if (offset < 0) {
    offsetSign = '-';
  }
  return year + '-' + formatTimeNumber(month + 1) + '-' + formatTimeNumber(day) +
    'T' + formatTimeNumber(hours) + ':' + formatTimeNumber(minutes) + ':' + formatTimeNumber(seconds) +
    offsetSign + formatTimeNumber(offsetHours) + ':' + formatTimeNumber(offsetMinutes);
}

export function formatTimeNumber(number: number): string {
  const numberVal = Math.abs(number);
  return numberVal < 10 ? `0${numberVal}` : `${numberVal}`;
}

export function getSecondsLeft(current: Moment, close: Moment, secondsLeft: number) {
  const startDateUnix = close.unix() - secondsLeft;

  const maxDiff = close.unix() - startDateUnix;
  const currDiff = close.unix() - current.unix();

  return maxDiff - currDiff;
}
