import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, timer } from 'rxjs';
import { Moment } from 'moment-timezone';
import * as moment from 'moment-timezone';
import { prepareDate } from '@helpers/dates';
import { debounceTime, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { IServerDate } from '@types';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  private _everySecond: Observable<number> = timer(0, 1000);

  private _currentDate: BehaviorSubject<Moment> = new BehaviorSubject<Moment>(null);
  private _currentDateAsObservable = this._currentDate.asObservable();

  get currentDate(): Observable<Moment> {
    return this._currentDateAsObservable;
  }

  get currentTime(){
    return this._currentDate.getValue().unix();
  }

  constructor(private ApiService: ApiService) {
    this._everySecond.subscribe(() => {
      if (this._currentDate.getValue()) {
        const currentTime = this._currentDate.getValue();
        this._currentDate.next(currentTime.add(1, 'seconds'));
      }
    });

    fromEvent(window, 'focus')
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.ApiService.get('current-date').pipe(take(1)).subscribe((data: IServerDate) => {
          this.setDatetime(data.date);
        })
      });
  }

  setDatetime(value: any) {
    if (value) {
      this._currentDate.next(prepareDate(value.date));
    }
  }
}
