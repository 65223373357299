import { AuctionImage } from '@types';
import { environment } from '../../environments/environment';

export function prepareAuctionImage(image: AuctionImage) {
  if (image) {
    return `${environment.google_cloud_url}${image.src}.webp`;
  }

  return getPlaceholderImage();
}

export function getPlaceholderImage() {
  return `${environment.google_cloud_url}web/placeholder.png`;
}

export function prepareCategoriesImage(key: string) {
  return `${environment.google_cloud_url}web/categories/${key}.webp`;
}

export function getLogoImage() {
  return `${environment.google_cloud_url}web/expert-logo.png`;
}
