import { getLastLanguage } from "./langs";

export function redirectHome() {
  return `/${getLastLanguage()}`;
}

export function redirectRestricted() {
  return `/${getLastLanguage()}/restricted`;
}

export function redirectNotFound() {
  return `/${getLastLanguage()}/404`;
}

export function redirectToAuction(slug:string){
  return `/${getLastLanguage()}/auction/${slug}`;
}

export function redirectToUrl(url: string) {
  return `/${getLastLanguage()}/${url}`;
}

export function redirectToUrlQuery(url: string, query: string) {
  return `/${getLastLanguage()}/${url}/q/${query}`
}

export function redirectToUrlSort(url: string, sort: string) {
  return `/${getLastLanguage()}/${url}/s/${sort}`
}

export function redirectToUrlSortQuery(url: string, query: string, sort: string) {
  return `/${getLastLanguage()}/${url}/q/${query}/s/${sort}`
}
