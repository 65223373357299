<shared-popup class="user-login-popup__shared">
  <div class="user-login-popup">
    <div class="user-login-popup__top">
      <div class="user-login-popup__welcome">{{ 'loginPopup.loginWelcome' | translate }}</div>
      <div class="user-login-popup__head">{{ 'loginPopup.loginHead' | translate }}</div>
    </div>
    <div class="user-login-popup__form">
      <app-login-form></app-login-form>
    </div>
  </div>
</shared-popup>
