<div class="popup">
  <svg
    class="popup__close"
    (click)="closePopup()"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"
    />
  </svg>
  <ng-content></ng-content>
</div>
