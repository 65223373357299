<form class="login-form" [formGroup]="login">
  <div class="form__row">
    <input
      class="form__input"
      type="email"
      formControlName="email"
      placeholder="{{ 'register.email' | translate }}"
    />
  </div>
  <div class="form__row">
    <div
      *ngIf="email.invalid && (email.dirty || email.touched)"
      class="form__alert form__alert--error"
    >
      <div *ngIf="email.errors.required">
        {{ "formErrors.fieldRequired" | translate }}
      </div>
      <div *ngIf="email.errors.email">
        {{ "formErrors.fieldEmail" | translate }}
      </div>
    </div>
  </div>
  <div class="form__row">
    <input
      class="form__input"
      type="password"
      formControlName="password"
      placeholder="{{ 'register.password' | translate }}"
    />
  </div>
  <div class="form__row">
    <div
      *ngIf="password.invalid && (password.dirty || password.touched)"
      class="form__alert form__alert--error"
    >
      <div *ngIf="password.errors.required">
        {{ "formErrors.fieldRequired" | translate }}
      </div>
    </div>
  </div>
  <div class="form__row">
    <div class="form__action-text">
      {{'loginPopup.cantLogin' | translate}} <a [routerLink]="rememberUrl" (click)="hidePopup()">{{'loginPopup.rememberPassword' | translate}}</a>
    </div>
  </div>
  <div class="form__row">
    <button class="form__button" (click)="sendForm()">{{'loginPopup.login' | translate}}</button>
  </div>
  <div class="form__row">
    <div class="form__action-text">
      {{'loginPopup.noAccount' | translate}} <a [routerLink]="registerUrl" (click)="hidePopup()">{{'loginPopup.registerAccount' | translate}}</a>
    </div>
  </div>
</form>
