import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private overlayRefStack: OverlayRef[] = [];

  constructor(private overlay: Overlay) { }

  open<T>(component: ComponentType<T>): T {
    const componentPortal = new ComponentPortal(component);

    const overlayConfig: OverlayConfig = {
      hasBackdrop: true,
      backdropClass: 'popup__backdrop',
      panelClass: 'popup__panel',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy: this.overlay.position().global().centerVertically().centerHorizontally()
    };

    const overlayRef = this.overlay.create(overlayConfig);

    this.overlayRefStack.push(overlayRef);

    overlayRef.backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        this.hideLatest();
      });

    const componentRef = overlayRef.attach(componentPortal);

    return componentRef.instance;
  }

  hideLatest() {
    const lastRef = this.overlayRefStack.pop();
    if (lastRef) {
      lastRef.detach();
    }
  }
}
