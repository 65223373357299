import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { TimeService } from './time.service';
import { IAuctionResponse, IFavoriteResponse, ISingleAuctionResponse } from '@types';
import { UserService } from './user.service';
import { take, tap } from 'rxjs/operators';
import { canRefresh, setAuctionRefreshDate } from '@helpers/auctions';
import { prepareDate, toW3CString } from '@helpers/dates';
import { NotificationService } from './notification.service';
import { PopupService } from './popup.service';
import { UserLoginPopupComponent } from '@components/user-login-popup/user-login-popup.component';
import { AuctionItem } from '../model/auction-item.model';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuctionService {

  private _auctionList: BehaviorSubject<AuctionItem[]> = new BehaviorSubject<AuctionItem[]>([]);
  private _auctionListAsObservable = this._auctionList.asObservable();
  private _commingAuctionList: BehaviorSubject<AuctionItem[]> = new BehaviorSubject<AuctionItem[]>([]);
  private _commingAuctionListAsObservable = this._commingAuctionList.asObservable();
  private _popularAuctionList: BehaviorSubject<AuctionItem[]> = new BehaviorSubject<AuctionItem[]>([]);
  private _popularAuctionListAsObservable = this._popularAuctionList.asObservable();

  private _auctionLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _auctionLoadingAsObservable = this._auctionLoading.asObservable();

  private _favAuctionsList: BehaviorSubject<AuctionItem[]> = new BehaviorSubject<AuctionItem[]>([]);
  private _favAuctionsListAsObservable = this._favAuctionsList.asObservable();

  private fbSubscriptions: Subscription[] = [];

  get auctionLoading() {
    return this._auctionLoadingAsObservable;
  }

  get auctionList(): Observable<AuctionItem[]> {
    return this._auctionListAsObservable;
  }

  get favAuctionList(): Observable<AuctionItem[]> {
    return this._favAuctionsListAsObservable;
  }

  get popularAuctionsList(): Observable<AuctionItem[]> {
    return this._popularAuctionListAsObservable;
  }

  get commingAuctionsList(): Observable<AuctionItem[]> {
    return this._commingAuctionListAsObservable;
  }

  constructor(
    private apiService: ApiService,
    private timeService: TimeService,
    private UserService: UserService,
    private NotificationService: NotificationService,
    private PopupService: PopupService,
    private db: AngularFirestore
  ) {
    combineLatest([this.UserService.favAuctionIds, this.auctionList]).subscribe(([ids, list]) => {
      const favourites = list.filter(item => ids.indexOf(item.id) > -1);
      this._favAuctionsList.next(favourites);
    });
  }

  refreshAuctionList() {
    if (canRefresh()) {
      this._auctionLoading.next(true);
      this.getAuctionList().subscribe((data: IAuctionResponse) => {
        this.setAuctionsList(data);
        this._auctionLoading.next(false);
      });
    }
  }

  getAuctionList(): Observable<IAuctionResponse> {
    setAuctionRefreshDate();
    return this.apiService.get('auction/list');
  }

  getAuction(slug: string) {
    return this.apiService.get<ISingleAuctionResponse>('auction/single/' + slug)
      .pipe(tap((data) => this.timeService.setDatetime(data.date)))
  }

  addNewAuction(auction: any) {
    return this.apiService.postMultipart('auction/add', auction);
  }

  addFavourite(auction: AuctionItem) {
    this.UserService.isLogged.pipe(take(1)).subscribe((isLogged) => {
      if (isLogged) {
        this.apiService.post<IFavoriteResponse>('favorite/add', { auction_id: auction.id })
          .pipe(take(1))
          .subscribe(data => {
            this.UserService.setUserFavAuctions(data.favorites.map(item => item.auction_id));
            this.NotificationService.pushSuccess('favorites_added');
          });
      } else {
        this.PopupService.open(UserLoginPopupComponent);
      }
    });
  }

  removeFavourite(auction: AuctionItem) {
    this.UserService.isLogged.pipe(take(1)).subscribe((isLogged) => {
      if (isLogged) {
        this.apiService.post<IFavoriteResponse>('favorite/remove', { auction_id: auction.id })
          .pipe(take(1))
          .subscribe(data => {
            this.UserService.setUserFavAuctions(data.favorites.map(item => item.auction_id));
            this.NotificationService.pushSuccess('favorites_removed');
          });
      } else {
        this.PopupService.open(UserLoginPopupComponent);
      }
    });
  }

  setAuctionsList(data: IAuctionResponse) {
    this.timeService.setDatetime(data.date);
    const currentDate = prepareDate(data.date.date);

    const auctionList: AuctionItem[] = [];
    data.items.forEach(item => {
      auctionList.push(new AuctionItem(item, currentDate));
    });

    this._auctionList.next(auctionList
      .sort((a, b) => a.startAt.unix() - b.startAt.unix())
      .sort((a, b) => {
        if (a.categoryId > 1) {
          return a.categoryId - b.categoryId
        }

        return 0;
      })
    );

    this._commingAuctionList.next(
      auctionList
        .filter(item => currentDate.unix() < item.startAt.unix())
        .sort((a, b) => a.startAt.unix() - b.startAt.unix())
        .slice(0, 10)
    );
    this._popularAuctionList.next(
      auctionList
        .filter(item => currentDate.unix() < item.closeAt.unix())
        .sort((a, b) => b.views - a.views)
        .slice(0, 10));

    this.updateAuctions();
  }

  getSimilarAuctions(currentAuction: AuctionItem) {
    return this._auctionList.getValue()
      .filter(auction => {
        return auction.id !== currentAuction.id &&
          auction.categoryId === currentAuction.categoryId &&
          this.getNearYear(currentAuction.production_year).indexOf(auction.production_year) >= 0
      })
      .sort((a, b) => this.getSimilar(currentAuction, b) - this.getSimilar(currentAuction, a))
      .slice(0, 3);
  }

  private getNearYear(year: number): number[] {
    return [year - 2, year - 1, year, year + 1, year + 2];
  }

  private updateAuctions() {
    const auctions = this._auctionList.getValue().filter(item => !item.closed);

    this.fbSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });

    auctions.forEach(item => {
      const subscription = this.db
        .collection(`auction/${item.slug}/offers`, ref => ref.orderBy('offer', 'desc'))
        .valueChanges()
        .subscribe((items: any[]) => {
          if (items && items[0]) {
            item.closeAt = prepareDate(toW3CString(items[0].close_at.toDate()))
            item.currentOffer = parseFloat(items[0].offer);
            item.offers = items.length;
            this.updateAuction(item);
          };
        });

      this.fbSubscriptions.push(subscription);
    });
  }

  private updateAuction(auction: AuctionItem) {
    const auctions = this._auctionList.getValue();
    const comming = this._commingAuctionList.getValue();
    const popular = this._popularAuctionList.getValue();

    [auctions, comming, popular].forEach((list, index) => {
      list.map(auctionItem => {
        if (auctionItem.id === auction.id) {
          return auction;
        }

        return auctionItem;
      });

      switch (index) {
        case 0:
          this._auctionList.next(list);
          break;
        case 1:
          this._commingAuctionList.next(list);
          break;
        case 2:
          this._popularAuctionList.next(list);
          break;
      }
    });
  }

  private getSimilar(currentAuction: AuctionItem, compareAuction: AuctionItem): number {
    const currentNameArray = currentAuction.name.split(' ').map(item => item.toLowerCase());
    const compareNameArray = compareAuction.name.split(' ').map(item => item.toLowerCase());
    const sum = currentNameArray.length + currentAuction.attribute.length;
    let counter = 0;

    currentNameArray.forEach(currentnameItem => {
      compareNameArray.forEach(compareNameitem => {
        if (currentnameItem === compareNameitem) {
          counter++;
        }
      });
    });

    currentAuction.attribute.forEach(currentAuctionItem => {
      compareAuction.attribute.forEach(compareAuctionItem => {
        if (currentAuctionItem.attribute_id === compareAuctionItem.attribute_id) {
          if (currentAuctionItem.value.toLowerCase() === compareAuctionItem.value.toLowerCase()) {
            counter++;
          }
        }
      })
    })

    return counter / sum;
  }
}
