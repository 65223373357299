import { Injectable } from '@angular/core';
import { IAuctionResponse, IInitialResponse, RegisterUserDTO } from '@types';
import { ApiService } from './api.service';
import { CategoryService } from './category.service';
import { ServerTranslationsService } from './server-translations.service';
import { TimeService } from './time.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { CurrencyService } from './currency.service';
import { AuctionService } from './auction.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class InitialService {

  private _isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isLoadedAsObservable = this._isLoaded.asObservable();

  private _isError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isErrorAsObservable = this._isError.asObservable();

  get isLoaded() {
    return this._isLoadedAsObservable;
  }

  get isError() {
    return this._isErrorAsObservable;
  }

  constructor(
    private apiService: ApiService,
    private categoryService: CategoryService,
    private serverTranslationsService: ServerTranslationsService,
    private CurrencyService: CurrencyService,
    private AuctionService: AuctionService,
    private UserService: UserService,
    private timeService: TimeService
  ) { }

  getInitialData() {
    combineLatest([this.apiService.get<IInitialResponse>('initial'), this.AuctionService.getAuctionList()])
      .pipe(take(1))
      .subscribe(([dataInitial, dataAuction]) => {
        this.serverTranslationsService.setTranslationList(dataInitial.translations);
        this.categoryService.setCategoryList(dataInitial.categories);
        this.timeService.setDatetime(dataInitial.date);
        this.CurrencyService.setCurrenciesRateList(dataInitial.currencies);
        this.AuctionService.setAuctionsList(dataAuction);

        const jwtToken = this.UserService.getLocalStorageToken();

        if (jwtToken) {
          const decoded = this.UserService.parseJwt(jwtToken);

          if (decoded.exp > this.timeService.currentTime) {
            this.apiService.post('refresh-token', {
              token: jwtToken
            })
              .pipe(switchMap((dataToken: { token: string }) => {
                this.UserService._token.next(dataToken.token);
                return this.apiService.get('user')
              }))
              .subscribe((data: { user: RegisterUserDTO }) => {
                this.UserService.setUser(this.UserService.token, data.user);
                this._isLoaded.next(true);
              }, () => {
                this.UserService._token.next(null);
                this._isLoaded.next(true);
              });
          } else {
            this._isLoaded.next(true);
          }
        } else {
          this._isLoaded.next(true);
        }

      }, () => {
        this._isError.next(true);
      });
  }

}
