import { Injectable } from '@angular/core';
import { CurrencyEnum, IAuctionResponse, IMyAccountAuctionResponse, IUserTokenResponse, LoginUserDTO, RegisterUserDTO } from '@types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  _token: BehaviorSubject<string> = new BehaviorSubject(null);
  private _tokenAsObservable = this._token.asObservable();
  private _user: Subject<RegisterUserDTO> = new Subject();

  private _favAuctionsIds: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  private _favAuctionsIdsAsObservable = this._favAuctionsIds.asObservable();

  get favAuctionIds(): Observable<number[]> {
    return this._favAuctionsIdsAsObservable;
  }

  get isLogged() {
    return this._tokenAsObservable.pipe(switchMap(async (val) => {
      return !!val
    }));
  }

  get token() {
    return this._token.getValue();
  }

  constructor(private apiService: ApiService, private TimeService: TimeService) { }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  registerUser(userDto: RegisterUserDTO) {
    return this.apiService.post('register', userDto);
  }

  rememberPassword(emailDto: { email: string }) {
    return this.apiService.post('remember', emailDto);
  }

  setNewPassword(newPasswordDto: { remember_token: string; password: string; password_repeat: string }) {
    return this.apiService.post('new-password', newPasswordDto);
  }

  verifyRememberToken(rememberToken: string) {
    return this.apiService.post('verify-remember-token', { rememberToken });
  }

  loginUser(loginUserDto: LoginUserDTO) {
    return this.apiService.post<IUserTokenResponse>('login', loginUserDto);
  }

  logoutUser() {
    return this.apiService.post('logout', {
      token: this.token
    }).pipe(tap(data => {
      this._token.next(null);
      this.setUserData(null);
      this.setUserFavAuctions([]);
      this.removeLocalStorageToken();
    }))
  }

  verifyUser(hash: string) {
    return this.apiService.post('verify', {
      hash
    });
  }

  setCurrency(currency: CurrencyEnum) {
    return this.apiService.post('user/currency', { currency });
  }

  setLanguage(lang: string) {
    return this.apiService.post('user/language', { lang });
  }

  setUser(token: string, user: RegisterUserDTO) {
    this._token.next(token);
    this.setUserData(user);
    this.setUserFavAuctions(user.favorites.map(item => item.auction_id));
    this.setLocalStorageToken(token);
  }

  setUserFavAuctions(ids: number[]) {
    this._favAuctionsIds.next(ids);
  }

  getCountries() {
    return this.apiService.get('countries');
  }

  setUserData(user: RegisterUserDTO) {
    this._user.next(user);
  }

  getActiveAuctions() {
    return this.apiService.get<IMyAccountAuctionResponse>('user/auctions/active');
  }

  getWaitingAuctions() {
    return this.apiService.get<IMyAccountAuctionResponse>('user/auctions/waiting');
  }

  getClosedAuctions() {
    return this.apiService.get<IMyAccountAuctionResponse>('user/auctions/closed');
  }

  getAuctionedActive() {
    return this.apiService.get<IMyAccountAuctionResponse>('user/auctions/auctioned/active');
  }

  getAuctionedClosed() {
    return this.apiService.get<IMyAccountAuctionResponse>('user/auctions/auctioned/closed');
  }

  getAuctionedWinnings() {
    return this.apiService.get<IMyAccountAuctionResponse>('user/auctions/auctioned/winnings');
  }

  getLocalStorageToken() {
    return window.localStorage.getItem('EXPERT_AUCTIONS_WT');
  }

  private setLocalStorageToken(token: string) {
    window.localStorage.setItem('EXPERT_AUCTIONS_WT', token);
  }

  private removeLocalStorageToken() {
    window.localStorage.removeItem('EXPERT_AUCTIONS_WT');
  }

}
