import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { take, timeout } from 'rxjs/operators';

export enum NotificationType {
  INFO,
  ERROR,
  SUCCESS
}

interface Notification {
  id: string;
  type: NotificationType;
  message: string;
  timer: number;
}

const DEFAULT_SPEED = 7000;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notification: BehaviorSubject<Notification[]> = new BehaviorSubject([]);
  private notificationAsObservable = this.notification.asObservable();

  get notifications() {
    return this.notificationAsObservable;
  }

  pushInfo(message: string, timer = DEFAULT_SPEED) {
    this.push(message, NotificationType.INFO, timer);
  }

  pushError(message: string, timer = DEFAULT_SPEED) {
    if (message) {
      this.push(message, NotificationType.ERROR, timer);
    } else {
      this.push('default_error', NotificationType.ERROR, timer);
    }
  }

  pushSuccess(message: string, timer = DEFAULT_SPEED) {
    this.push(message, NotificationType.SUCCESS, timer);
  }

  private push(message: string, type: NotificationType, timer) {
    const id = this.randomId();

    this.notification.next([].concat(...this.notification.getValue(), {
      id,
      type,
      message,
      timer
    }));

    this.remove(id, timer);
  }

  private remove(id, speed: number) {
    timer(speed)
      .pipe(take(1))
      .subscribe(() => {
        const notifications = this.notification.getValue().filter(item => item.id !== id);
        this.notification.next(notifications);
      });
  }

  private randomId() {
    return [...Array(10)].map(i => (~~(Math.random() * 36)).toString(36)).join('');
  }
}
