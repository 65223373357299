import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { setLastLang } from '@helpers/langs';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  private _currentLang: BehaviorSubject<string> = new BehaviorSubject<string>(this.router.url.split('/')[1]);
  private _currentLangAsObservable = this._currentLang.asObservable();

  get currentLang() {
    return this._currentLangAsObservable;
  }

  get currentLangValue() {
    return this._currentLang.getValue();
  }

  urls = [
    'pl',
    'en', 'ru'
  ];

  constructor(private router: Router, private translate: TranslateService) {
    this.changeLanguage(this._currentLang.getValue());
  }

  changeLanguage(lang: string) {
    const trimmedLang = lang.split('?')[0];
    const url = this.router.url.split('/');
    url[1] = trimmedLang;
    const newUlr = url.join('/');
    this.router.navigateByUrl(newUlr);
    this._currentLang.next(trimmedLang);
    this.translate.use(trimmedLang);
    setLastLang(trimmedLang);
  }
}
