const CURRENT_LANG_KEY = "CURRENT_LANG";

export function getLastLanguage() {
  const currentLang = window.localStorage.getItem(CURRENT_LANG_KEY);

  if (currentLang) {
    return currentLang;
  }

  return 'pl';
}

export function setLastLang(lang){
  window.localStorage.setItem(CURRENT_LANG_KEY, lang);
}
