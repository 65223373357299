import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  BASE_API_URL = environment.api_url;

  constructor(private http: HttpClient) { }

  get<T>(url: string, extraHeaders?: any) {
    return this.http.get<T>(`${this.BASE_API_URL}/${url}`, {
      headers: this.getHeaders(extraHeaders)
    });
  }

  post<T>(url: string, body: any, extraHeaders?: any) {
    return this.http.post<T>(`${this.BASE_API_URL}/${url}`, body, {
      headers: this.getHeaders(extraHeaders)
    })
  }

  postMultipart<T>(url: string, body: any, extraHeaders?: any) {
    return this.http.post<T>(`${this.BASE_API_URL}/${url}`, body, {
      headers: extraHeaders,
    });
  }

  delete<T>(url: string, extraHeaders?: any) {
    return this.http.delete<T>(`${this.BASE_API_URL}/${url}`, {
      headers: this.getHeaders(extraHeaders)
    });
  }

  put<T>(url: string, body: any, extraHeaders?: any) {
    return this.http.put<T>(`${this.BASE_API_URL}/${url}`, body, {
      headers: this.getHeaders(extraHeaders)
    })
  }

  private getHeaders(extra: any) {
    return Object.assign(
      {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      extra
    )
  }
}
