import { prepareDate } from "@helpers/dates";
import { getPlaceholderImage, prepareAuctionImage } from "@helpers/images";
import { redirectToAuction } from "@helpers/redirects";
import { IAuctionAttribute, MyAuctionsVehicleListItem, VehicleListItem, VehicleLocalization, VehicleStatus } from "@types";
import { Moment } from "moment";

export class AuctionItem {

  private _item: VehicleListItem;
  private _currentDate: Moment;

  url: string;
  images: string[];
  thumb: string;
  flag: string;
  startAt: Moment;
  openAt: Moment;
  closeAt: Moment;
  closed: boolean;
  currentOffer: number;
  categoryId: number;
  description: string;
  attribute: IAuctionAttribute[];
  contract: 'margin_vat' | 'contract' | 'invoice_vat';
  id: number;
  name: string;
  production_year: number;
  slug: string;
  status: VehicleStatus;
  localization: VehicleLocalization;
  views: number;
  offers: number;

  constructor(item: VehicleListItem, currentDate: Moment) {
    this._item = item;
    this._currentDate = currentDate;

    this.prepareInit();
    this.prepareUrl();
    this.prepareImage();
    this.prepareFlag();
    this.prepareDate();
    this.prepareStatus();
    this.prepareCurrentOffer();
  }

  prepareUrl() {
    this.url = redirectToAuction(this._item.slug);
  }

  private prepareInit() {
    this.categoryId = this._item.category_id;
    this.description = this._item.description;
    this.attribute = this._item.attribute;
    this.contract = this._item.contract;
    this.id = this._item.id;
    this.name = this._item.name;
    this.production_year = this._item.production_year;
    this.slug = this._item.slug;
    this.status = this._item.status;
    this.localization = this._item.localization;
    this.views = this._item.views;
    this.offers = this._item.offers_count;
  }

  private prepareCurrentOffer() {
    if (this._item.highest_offer) {
      this.currentOffer = this._item.highest_offer.offer;
    } else {
      this.currentOffer = this._item.start_price;
    }
  }

  private prepareStatus() {
    this.closed = this._currentDate.local() > this.closeAt.local();
  }

  private prepareImage() {
    if (this._item.images.length > 0) {
      this.images = this._item.images
        .sort((a, b) => a.order - b.order)
        .map(item => {
          return prepareAuctionImage(item);
        });

      const thumb = this._item.images.find(item => item.thumb === 1);

      if (thumb) {
        this.thumb = prepareAuctionImage(thumb);
      } else {
        this.thumb = prepareAuctionImage(this._item.images[0]);
      }
    } else {
      this.images = [getPlaceholderImage()];
      this.thumb = getPlaceholderImage();
    }
  }

  private prepareFlag() {
    this.flag = this._item.localization.country.flag;
  }

  private prepareDate() {
    this.startAt = prepareDate(this._item.start_at);
    this.openAt = prepareDate(this._item.open_at);
    this.closeAt = prepareDate(this._item.close_at);
  }
}

export class MyAuctionItem extends AuctionItem {
  favs: number;

  constructor(item: MyAuctionsVehicleListItem, currentDate: Moment) {
    super(item, currentDate);
    this.favs = item.favs_count;
  }
}
