import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/user.service';
import { Router } from '@angular/router';
import { PopupService } from '@services/popup.service';
import { NotificationService } from '@services/notification.service';
import { IUserTokenResponse, LoginUserDTO } from '@types';
import { redirectHome, redirectToUrl } from '@helpers/redirects';
import { CurrencyService } from '@services/currency.service';
import { LangService } from '@services/lang.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  login = this.buildEmptyLoginForm();

  get email() {
    return this.login.controls['email'];
  }

  get password() {
    return this.login.controls['password'];
  }

  registerUrl = redirectToUrl('register-user');
  rememberUrl = redirectToUrl('remember-password');

  constructor(
    private UserService: UserService,
    private PopupService: PopupService,
    private CurrencyService: CurrencyService,
    private LangService: LangService,
    private NotificationService: NotificationService
  ) { }

  ngOnInit(): void {
  }

  sendForm() {
    this.login.markAllAsTouched();

    if (this.login.valid) {
      this.UserService.loginUser(this.login.value as LoginUserDTO).subscribe((data: IUserTokenResponse) => {
        this.UserService.setUser(data.token, data.user);
        this.CurrencyService.setCurrency(data.user.prefered_currency);
        this.LangService.changeLanguage(data.user.prefered_lang);
        this.PopupService.hideLatest();
        this.NotificationService.pushSuccess('logged_in');
      }, (error) => {
        this.NotificationService.pushError(error.error.message);
      });
    }
  }

  buildEmptyLoginForm() {
    return new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl('', {
        validators: [Validators.required]
      })
    });
  }

  hidePopup() {
    this.PopupService.hideLatest();
  }
}
