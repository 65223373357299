import { Injectable } from '@angular/core';
import { ITranslation, ITranslationResponse } from '@types';
import { ApiService } from './api.service';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class ServerTranslationsService {

  private _translatonsList: ITranslation[];

  get translationsList(): ITranslation[] {
    return this._translatonsList;
  }

  setTranslationList(data: ITranslation[]){
    this._translatonsList = data;
  }

}
