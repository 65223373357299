import { Component, Input } from "@angular/core";
import { AuctionItem } from "src/app/model/auction-item.model";

export enum UserType {
  Admin = 1,
  Moderator = 2,
  PrivateUser = 3,
  CompanyUser = 4
}

export interface IUser {
  id: number;
  user_type: UserType;
  name: string;
  email: string;
}

export interface IPrivateUser extends IUser {
  phone: string;
  city: string;
  zip_code: string;
  address: string;
  country: ICountry;
}

export interface ICompanyUser extends IPrivateUser {
  compnay_name: string;
  company_nip: string;
}

export interface RegisterResponse {

}

export interface IServerDate {
  date: {
    date: string;
    timezone: string;
    timezone_type: number;
  }
}

export interface ITranslation {
  group: string;
  id: number;
  key: string;
  lang: string;
  value: string;
}

export interface IAttribute {
  id: number;
  category_id: number;
  attribute_order: number;
  attribute_key: string;
  attribute_type: string;
  dictionary: IAttributeDictionary[];
}

export interface IAuctionAttribute {
  id: number;
  attribute_id: number;
  auction_id: number;
  value: string;
}

export interface DescriptionAttribute {
  key: string;
  value: string;
  type: string;
}

export interface IAttributeDictionary {
  id: number;
  attribute_id: number;
  dict_key: string;
}

export interface ICategory {
  id: number;
  category_key: string;
  category_slug: string;
  order: number;
  active: boolean;
  auctions_count: number;
  attributes: IAttribute[];
}

export interface ICategoryMenu {
  link: string;
  translate: string;
  active: boolean;
  order: number;
}

export interface ICategoryWithAttribute extends ICategory {
  attributes: IAttribute[];
}

export interface Items<T> {
  items: T[];
}

export interface IUserTokenResponse extends IUserResponse {
  token: string;
}
export interface IUserResponse {
  user: RegisterUserDTO;
}
export interface ICategoryResponse extends Items<ICategory>, IServerDate { }
export interface ICategoryWithAttributeResponse extends Items<ICategoryWithAttribute>, IServerDate { }
export interface ITranslationResponse extends Items<ITranslation>, IServerDate { }
export interface IAuctionResponse extends Items<VehicleListItem>, IServerDate { }
export interface IMyAccountAuctionResponse extends Items<MyAuctionsVehicleListItem>, IServerDate { }
export interface ICountryResponse extends IServerDate {
  countries: ICountry[];
}
export interface ISingleAuctionResponse extends IServerDate {
  auction: VehicleListItem;
}

export interface IFavoriteResponse extends IServerDate {
  favorites: {
    auction_id: number
  }[];
}

export interface IInitialResponse {
  categories: ICategoryWithAttribute[];
  translations: ITranslation[];
  date: IServerDate;
  currencies: CurrencyRate[];
}

export enum RegisterTypeEnum {
  Personal = 3,
  Company = 4
}

export enum CurrencyEnum {
  EUR = 'EUR',
  USD = 'USD',
  RUB = 'RUB',
  PLN = 'PLN'
}

export interface RegisterUserDTO {
  id: number | null;
  user_type: RegisterTypeEnum,
  email: string;
  password: string;
  password_repeat: string;
  prefered_lang: string;
  prefered_currency: CurrencyEnum;
  name: string;
  surname: string;
  country: number;
  address: string;
  zip_code: string;
  city: string;
  phone_prefix: string;
  phone: string;
  company_nip: string;
  company_name: string;
  favorites: {
    id: number;
    user_id: number;
    auction_id: number
  }[];
}

export interface LoginUserDTO {
  email: string;
  password: string;
}

export enum VehicleStatus {
  INACTIVE,
  ACTIVE,
  CLOSED,
  ARCHIVED
}

export interface AuctionImage {
  id: number;
  src: string;
  order: number;
  thumb: number;
}

export interface VehicleListItem {
  category_id: number;
  close_at: string;
  description: string;
  attribute: IAuctionAttribute[];
  contract: 'margin_vat' | 'contract' | 'invoice_vat';
  id: number;
  images: AuctionImage[];
  name: string;
  production_year: number;
  slug: string;
  start_at: string;
  open_at: string;
  start_price: number;
  status: VehicleStatus;
  localization: VehicleLocalization;
  views: number;
  offers_count: number;
  highest_offer: IOffer;
}

export interface MyAuctionsVehicleListItem extends VehicleListItem {
  favs_count: number;
}

export interface VehicleListItemSimilar extends VehicleListItem {
  similar: number;
}

interface IOffer {
  auction_id: number;
  id: number;
  offer: number;
  slug: string;
  user_id: number;
}

export interface VehicleLocalization {
  address: string;
  city: string;
  country: ICountry;
  zip_code: string;
}

export interface ICountry {
  currency_code: string;
  currency_name: string;
  flag: string;
  lat: number;
  lng: number;
  name: string;
  numeric_code: number;
  region: string;
  alpha2Code: string;
  alpha3Code: string;
  calling_codes: string;
}

export interface CurrencyRate {
  currency: string;
  rate: number;
  date: string;
}

@Component({
  template: ''
})
export abstract class AuctionPage {
  @Input() auction: AuctionItem;
}
