import { Injectable } from '@angular/core';
import { prepareCategoriesImage } from '@helpers/images';
import { ICategory, ICategoryMenu, ICategoryWithAttributeResponse } from '@types';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private _categoryList: BehaviorSubject<ICategory[]> = new BehaviorSubject<ICategory[]>(null);
  private _categoryListAsObservable = this._categoryList.asObservable();

  get categoryList(): Observable<ICategory[]> {
    return this._categoryListAsObservable;
  }

  constructor() { }

  setCategoryList(items: ICategory[]) {
    this._categoryList.next(items.sort((a, b) => a.order - b.order));
  }

  getAttributes(categoryId: number) {
    const category = this._categoryList.getValue().find(item => item.id === categoryId);

    if (category) {
      return category.attributes;
    }

    return;
  }

  getCategoryId(slug?: string): number {
    const categoryOrDefault = this.getCategoryOrDefault(slug, 0);

    if (this.isCategory(categoryOrDefault)) {
      return categoryOrDefault.id;
    }

    return categoryOrDefault;
  }

  getCategoryKey(slug?: string): string {
    const categoryOrDefault = this.getCategoryOrDefault(slug, 't_all');

    if (this.isCategory(categoryOrDefault)) {
      return categoryOrDefault.category_key
    }

    return categoryOrDefault;
  }

  getCategoryMenu(): ICategoryMenu[] {
    const categories = this._categoryList.getValue();

    return categories.map(item => {
      return {
        link: `auctions/${item.category_slug}`,
        active: item.active,
        order: item.order,
        translate: item.category_key,
        image: prepareCategoriesImage(item.category_key)
      }
    })
  }

  private isCategory(test: any): test is ICategory {
    return (test as ICategory).id !== undefined;
  }

  private getCategoryOrDefault<T>(slug: string, defaultValue: T): ICategory | T {
    const categoryList = this._categoryList.getValue();

    if (!categoryList) {
      return defaultValue;
    }

    const category = categoryList.find(item => item.category_slug === slug);

    if (category) {
      return category;
    }

    return defaultValue;
  }
}
