import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { LangSwitcherComponent } from './lang-switcher/lang-switcher.component';
import { RouterModule } from '@angular/router';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeadComponent } from './head/head.component';
import { PopupComponent } from './popup/popup.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ServerTranslatePipe } from '@pipes/server-translate.pipe';
import { ExpertCurrencyPipe } from '@pipes/expert-currency.pipe';
import { MessageComponent } from './message/message.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { CurrencySwitcherComponent } from './currency-switcher/currency-switcher.component';

@NgModule({
  declarations: [
    MenuComponent,
    LangSwitcherComponent,
    CurrencySwitcherComponent,
    SearchBarComponent,
    HeadComponent,
    PopupComponent,
    ServerTranslatePipe,
    ExpertCurrencyPipe,
    MessageComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  exports: [
    MenuComponent,
    LangSwitcherComponent,
    CurrencySwitcherComponent,
    SearchBarComponent,
    HeadComponent,
    TranslateModule,
    PopupComponent,
    ServerTranslatePipe,
    ExpertCurrencyPipe,
    MessageComponent,
    LoaderComponent
  ]
})
export class SharedModule { }
