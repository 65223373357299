import { CurrencyEnum } from "@types";

const CURRENT_CURRENCY_KEY = "CURRENT_CURRENCY";

export function getCurrency(): CurrencyEnum {
  const currency = window.localStorage.getItem(CURRENT_CURRENCY_KEY) as CurrencyEnum;

  if (currency) {
    return currency;
  }

  return CurrencyEnum.EUR;
}

export function setCurrency(currency: CurrencyEnum) {
  window.localStorage.setItem(CURRENT_CURRENCY_KEY, currency);
}
