export const environment = {
  production: true,
  google_cloud_url: 'https://storage.googleapis.com/expert-6e18e.appspot.com/',
  api_url: 'https://expert.pixelperfect.com.pl/api',
  max_file_uploads: 20,
  firebase_config: {
    apiKey: "AIzaSyBAo7UdcJN14xd1747T05gi4KTv8AA7waU",
    authDomain: "expert-6e18e.firebaseapp.com",
    databaseURL: "https://expert-6e18e.firebaseio.com",
    projectId: "expert-6e18e",
    storageBucket: "expert-6e18e.appspot.com",
    messagingSenderId: "232057659670",
    appId: "1:232057659670:web:98df5d0388f1b0112a7037",
    measurementId: "G-N6DHYBNQM8"
  }
};
