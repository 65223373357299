const LAST_AUCTIONS_REFRESH_KEY = 'LAST_AUCTIONS_REFRESH';

export function canRefresh() {
  const current = Date.now();
  const last = getAuctionRefreshDate();

  return (current - last) > (60 * 1000);
}

export function setAuctionRefreshDate() {
  window.localStorage.setItem(LAST_AUCTIONS_REFRESH_KEY, Date.now().toString());
}

function getAuctionRefreshDate() {
  const storageValue = window.localStorage.getItem(LAST_AUCTIONS_REFRESH_KEY);

  if (storageValue) {
    return parseInt(storageValue);
  }

  return 0;
}
